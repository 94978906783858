<template>
  <div>
    <vs-card>
      <vs-row
        vs-type="flex"
        vs-justify="center"
      >
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="3"
        >
          <import-excel :on-success="importExcelFile"/>
        </vs-col>
      </vs-row>
    </vs-card>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      @emitAddNewRecord="createRecord()"
      @emitDeleteMultipleRecords="deleteGroup($event, null , true)"
    />
    <vs-popup
      title="تفاصيل المجموعة"
      :active.sync="popupActive"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <div class="vx-row">
            <div class="vx-col w-full">
              <ValidationProvider
                name="الاسم"
                rules="required"
                v-slot="{ errors }"
              >
                <label>الاسم</label>
                <vs-input
                  placeholder="اسم المجموعة"
                  v-model="group.name"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                حفظ
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
    <vs-popup
      title="تفاصيل المجموعة"
      :active.sync="popupActiveDetails"
    >
      <form>
        <hr style="height:1px;border-width:0;background-color:#BDBAC2">
        <div class="vx-row mt-10">
          <div class="vx-col sm:w-1/3 w-full">
            <h4>الاسم : </h4>
          </div>
          <div class="vx-col sm:w-2/3 w-full mb-2">
            <h4>{{ group.name }}</h4>
            <br>
          </div>
        </div>
      </form>
    </vs-popup>
    <vs-popup
      title="أرسل رسالة لهذه المجموعة"
      :active.sync="popupActiveSendMessage"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
      >
        <form>
          <div class="vx-row mt-5">
            <div class="vx-col w-full mb-2">
              <ValidationProvider
                name="نص الرسالة"
                rules="required"
                v-slot="{ errors }"
              >
                <label>نص الرسالة</label>
                <vs-input
                  placeholder="الرسالة"
                  v-model="message.text"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmitMessage())"
              >
                إرسال
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../shared-components/ag-grid/AgTable';
import AgTableBtnCell from '../shared-components/ag-grid/AgTableBtnCell.vue';
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import utilities from "@/app/shared/utilities";
import toasted from "@/app/shared/utilities/toasted";
import AgButton from "@/app/shared/shared-components/ag-grid/AgButton";
import ImportExcel from "../../../components/excel/ImportExcel";

const groupRepo = SharedRepositoryFactory.get('groupRepository');

export default {
  name: "GroupsMain",
  components: {
    ImportExcel,
    AgTable
  },

  data() {
    return {
      gridOptions: null,
      gridApi: null,
      rowData: [],
      group: {
        id: '',
        name: '',
      },
      isLoading: true,
      popupActiveDetails: false,
      popupActiveSendMessage: false,
      popupActive: false,
      message: {
        groupId: null,
        text: '',
      },
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 225,
          checkboxSelection: true,
        },
        {
          headerName: 'الاسم',
          field: 'name',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'أعضاء المجموعة',
          cellRendererFramework: AgButton,
          field: 'id',
          filter: false,
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'أعضاء المجموعة',
            type: 'gradient',
            click(id) {
              self.rowClickedRoles(id);
            }
          }
        },
        {
          headerName: 'إرسال رسالة',
          cellRendererFramework: AgButton,
          field: 'id',
          filter: false,
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'أرسل رسالة',
            type: 'gradient',
            click(id) {
              self.sendMessage(id);
            }
          }
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            deleteRecord: function (id, rowIndex) {
              self.deleteGroup(id, rowIndex);
            },
            editRecord: function (group) {
              self.editRecord(group);
            },
            viewRecordDetails: function (group) {
              self.viewRecordDetails(group);
            },
            actions: ['view', 'edit', 'delete']
          },

        },
      ];
    },
    sendMessage(id) {
      this.popupActiveSendMessage = true;
      this.message.groupId = id;
    },
    onSubmitMessage() {
      if (/[۰۱۲۳٤٥٦٧۸۹]/.test(this.message.text))
        toasted.failed('لا يمكن إرسال أرقام عربية');
      else {
        groupRepo.sendMessage(this.message).then((response) => {
          this.handleResponse(response.data);
          this.isLoading = false;
        });
      }
    },
    handleResponse() {
      this.popupActiveSendMessage = false;
      this.popupActive = false;
      this.popupActiveDetails = false;
    },
    rowClickedRoles(id) {
      this.$router.push(`/groups/${id}/members`);
    },
    createRecord() {
      utilities.initObjectMembers(this.group);
      this.openPopup();
    },
    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    onSubmit() {
      this.isLoading = true;
      if (this.group.id !== null) {
        this.updateGroup(this.group);
      } else {
        this.storeGroup(this.group);
      }
    },
    // used to fire the dialog which response to edit current record in the table
    editRecord(group) {
      Object.assign(this.group, group);
      this.openPopup();
    },
    storeGroup(group) {
      groupRepo.storeGroup(group).then((response) => {
        this.rowData.unshift(response.data.data);
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },
    deleteGroup(ids, index, isMultiple = false) {
      this.isLoading = true;
      if (isMultiple)
        ids = ids.join(',');
      groupRepo.deleteGroup(ids).then((response) => {
        if (response.code === 200) {
          if (isMultiple)
            this.fetchAllGroups();
          else {
            this.rowData.splice(index, 1);
            this.rowData = [...this.rowData];
          }
        }
        this.isLoading = false;
      });
    },
    updateGroup(group) {
      groupRepo.updateGroup(group).then((response) => {
        let index = this.rowData.map((group) => {
          return group.id;
        }).indexOf(response.data.data.id);
        this.rowData[index] = response.data.data;
        this.rerender();
        this.handleResponse(response.data);
        this.isLoading = false;
      });
    },
    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.group = record;
    },
    fetchAllGroups() {
      groupRepo.fetchAllGroups().then((response) => {
        this.rowData = response;
        this.isLoading = false;
      });
    },
    importExcelFile(excelData) {
      groupRepo.importGroupFromExcel(excelData.rawFile).then(() => {
        this.fetchAllGroups();
        this.isLoading = false;
      });
    }
  },

  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.fetchAllGroups();
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>
